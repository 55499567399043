@import '../../../variables.scss';

.rounded-input {
    border-radius: 5rem !important;

    input {
        border-radius: 5rem !important;
    }
}

.ui.active.selection.dropdown.rounded-input {
    border-radius: 1rem !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}


.Input {
    width: 100%;
}

.fat {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
}

.ui.icon.input>i.icon::after {
    content: "";
    width: 100%;
    height: 50%;
    top: 0;
    border-right: 1px solid $detail-color;
    right: 0;
    top: 50%;
    transform: translateY(-25%);
}

.ui[class*="left icon"].input>input {
    padding-left: 3.5em !important;
}

.input-container {
    
    background: $white;
    border-radius: 5rem !important;
    border: 1px solid rgba(34,36,38,.15);
    align-items: center;
    padding-right:.5em;
    padding-left:.5em;

    .Input {
        text-align: left;
    }

    .ui.input>input {
        border:none;
        padding-top:.3em;
        padding-bottom: .3em;
        text-align: left !important;
    }

    .input-icon.right {
        // height: 1.5em;
        color:$detail-color;
        cursor: pointer;
        margin-right:1em;
        
        &:hover {
            color:$black;
        }
    }
}