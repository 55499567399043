body {

    .Reports {
        .p-dropdown label.p-dropdown-label {
            text-align: left;

        }

        .p-dropdown-filter-container {
            width: 100%;
        }

        .p-calendar .p-inputtext {
            border-radius: 5rem !important;
            min-width: 15em;
        }

        .p-multiselect {
            display: flex;
            margin: 0 !important;
            margin-right: .5em !important;
            font-size: .8em;
            line-height: .8em;
            min-height: 2em;

            .p-multiselect-label-container {
                display: flex;
                align-items: center;
            }

        }

        .wrapped {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}