@import '../../../../../variables.scss';

.Reservations {
    

    .p-datatable .p-datatable-thead>tr>th {
        text-align: left;
    }

    .p-datatable .p-datatable-tbody>tr {
        text-align: left;

    }


    .p-calendar input {
        border-radius: 0px !important;
        min-height: 2.5em;
    }



    .circle-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            flex: 1;
            max-width: 3em;
            margin-right: 1em;

            .inner-circle {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2em;
                height: 2em;
                border-radius: 50%;

                i {
                    display: flex;
                    font-size: 1em;
                    margin-left: .1em;


                    &.icon-cv19-circle-check {
                        margin-top: .1em;
                    }
                }
            }

            &.approved {
                background: $lightest-green;

                .inner-circle {
                    background: $green;
                }

                i {
                    color: $white !important;
                }
            }

            &.pending {
                background: $pending-yellow-light;

                .inner-circle {
                    background: $pending-yellow;
                }

                i {
                    color: $white !important;
                }
            }

            &.rejected {
                background: $delete-red-light;

                .inner-circle {
                    background: $delete-red;
                }

                i {
                    color: $white !important;
                }
            }
            &.cancelled_no_show{
                background: $light-gray;

                .inner-circle {
                    background: $gray;
                }

                i {
                    color: $white !important;
                }
            }
            &.cancelled {
                background: $delete-red-light;

                .inner-circle {
                    background: $delete-red;
                }

                i {
                    color: $white !important;
                }
            }

            &.info {
                background: $light-blue;

                .inner-circle {
                    background: $blue;
                }

                i {
                    color: $white !important;
                }
            }

            &.checkedin {
                background: $pending-yellow-light;

                .inner-circle {
                    background: $pending-yellow;
                }

                i {
                    color: $white !important;
                }
            }

            &.checkout {
                background: $light-gray;

                .inner-circle {
                    background: $gray;
                }

                i {
                    color: $white !important;
                }
            }


        }
    }
}