.UserModal {
  // position: relative !important;

  .usermodal {
    padding-left: 1em;
    padding-right: 2.1em;

    .p-dropdown-trigger {
      background-color: transparent !important;
      margin-right: .4em;
    }

    label {
      background: transparent;
    }
  }

}

.dimmed.dimmable>.ui.animating.dimmer,
.dimmed.dimmable>.ui.visible.dimmer,
.ui.active.dimmer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui.modal,
.ui.active.modal {
  margin: 0 auto !important;
  top: auto !important;
  left: auto !important;
  transform-origin: center !important;
  transition: all ease .5s;
  height: auto;
}

.p-multiselect-header {
  padding: 0.429em 0.857em;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
  margin: 0;
}

.p-multiselect {
  padding: .78571429em 2.1em .78571429em 1em;
  border: 1px solid rgba(34, 36, 38, .15) !important;

  &:hover {
    border-color: rgba(34, 36, 38, .35) !important;
  }

  .p-multiselect-trigger {
    background-color: transparent !important;

    margin-right: .2em;
  }

  .p-multiselect-label {
    margin: 0;
    padding: 0 !important;

    .ruoli {
      margin-right: 1em;
    }

    .placeholder {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}