@import '../../../../../variables.scss';

.Biohazard {

    .p-autocomplete .p-autocomplete-input {
        border-top-left-radius: 5rem;
        border-bottom-left-radius: 5rem;
    }

    .p-autocomplete-dd .p-autocomplete-dropdown {
        border-top-right-radius: 5rem;
        border-bottom-right-radius: 5rem;
        background-color: $cgreen;
        border-color: $cgreen;
    }

    .p-dropdown label.p-dropdown-label {
        text-align: left;

    }

    .p-dropdown-filter-container {
        width: 100%;
    }

    .p-calendar .p-inputtext {
        border-radius: 5rem !important;
        min-width: 15em;
    }

}