@import '../../../variables.scss';

.headerContainer {
    position: relative;
    display: flex;
    padding: 5% 0;
    color: white;
    font-size: 1.2em;
    font-weight: 500;

    .headerRight {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
    }

    .headerTitle {
        align-self: center;
        justify-self: center;
    }

    .headerRightButton {
        display: flex;
        align-items: center;
        cursor: pointer;

        i {
            font-size: 1.4em;
        }
    }

    i.inverted.bordered.icon, i.inverted.circular.icon {
        background-color: white !important;
        color: $green !important;
        margin-right: 5% !important;
    }

}