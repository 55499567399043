@import '../../../../variables.scss';

.colleagueContainer {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    // border-bottom: 0.5px solid rgba(112, 112, 112, 0.2);
    padding-bottom: 0.5em;
    padding-top: 0.5em;

    .colleagueWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .colleagueInfo {
        font-weight: 500;
    }

    .colleagueMail {
        color: grey;
        font-size: 0.75em;
    }

    .colleagueCheckbox {
        display: flex;
        align-items: center;
    }

    .roundCheckbox{
        label::before {
            border-radius: 50%;
            transform: scale(1.3);
        }
    }

    .ui.checkbox input:checked~.box:before, .ui.checkbox input:checked~label:before {
        background-color: rgba(96, 194, 107, 1);
        border: 0;
    }

    .ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
        color: white;
    }

    .p-datatable-thead {
        display:none;
    }

}


.searchBar {

    height: 8%;
    margin-bottom: 4%;

    .input {
        width: 100% !important;
    }

    input {
        border: unset !important;
        width: 100% !important;
        height: 100% !important;
        border-radius: 5rem !important;
        font-size: 16px !important;
        outline: none !important;
    }

    i {
        background-color: $cyan !important;
        color: white !important;
        opacity: 1 !important;
    }

}

.table {
    margin: 0 auto;
}

.colleaguesSelected {
    justify-content: flex-end;
    color: grey;
    display: flex;
}

.p-paginator {
    background-color: transparent !important;
    margin-top: 2rem !important;
    display: flex;
    justify-content: flex-end;
  }
  
  .p-paginator .p-paginator-element {
    border: 1px solid #cecece !important;
    margin-right: .4rem !important;
    margin-left: .4rem !important;
  }
  
  body .p-datatable .p-sortable-column.p-highlight, .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: rgba(48, 185, 151, 1) !important;
    border: none !important;
  }

  body .p-datatable .p-datatable-tbody>tr {
    background-color: white !important;
    }

    .p-datatable {
    background-color: white;
    background: white;
    border: none;
    border-radius: 5em;
    }
    .p-datatable {
        background-color: white !important;
        background: white;
        border: none;
        border-radius: 5em;
    }



    body .p-datatable .p-datatable-header { 
        background-color: white !important;
        background: white;
    }
    .p-datatable .p-paginator-bottom {
        border: 0 none !important;
    }

    .p-autocomplete .p-autocomplete-panel {
        max-height: 350px!important;
    }
