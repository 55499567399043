@import '../../../../variables.scss';

.container {
    display: flex;
    flex-direction: column;
    margin-top:5px;
}

.containerRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.containerCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.containerElement {
    margin-bottom: 10px;
}

.containerTags {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.mycard {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background-color: $cgreen;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.contentCard {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
}
    
.labelContent {
    display: flex; 
    //display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 15px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgb(172, 171, 169);
    padding: 5px;
}

.labelContentWeb {
    display: flex; 
    //display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 15px;
    border-radius: 20px;
    background-color: white;
    //box-shadow: 0px 0px 10px 0px rgb(172, 171, 169);
    padding: 5px;
}
    
.labelEl {
    width: 250px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center; 
    font-size: 15px;
    border-radius: 20px;
    color: $cgreen;
}

.separator {
    height: 30px;
    border-left: 1px solid grey;
    margin-right: 10px;
}

.separatorH {
    border:none;
    border-top: 1px solid #d4d4d5;
    height: 100%;
}

.marginIconLeft {
    display: flex;
    color: $cgreen;
    //margin-left: 2em;
    justify-content: flex-end;
}

.marginIconRight {
    color: $cgreen;
    margin-right: 20px;
}

.fontSize {
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;
}

.calendar {
    display: contents;
    margin-top: 20px;
    .p-datepicker table {
        font-size: 10px;
        margin: 0.857em 0 0 0;
    }
    @media only screen and (max-width: 760px) {
        .p-datepicker.p-datepicker-inline {
            margin-top: 5px;
            margin-bottom: 20px;
            display: contents;
            padding-left: 0.1em;
            padding-right: 0.1em;
        }
    }
    @media only screen and (min-width: 759px) {
        .p-datepicker.p-datepicker-inline {
            margin-top: 5px;
            margin-bottom: 20px;
            padding-left: 0.1em;
            padding-right: 0.1em;
        }
    } 

    .p-calendar {
        display: flex;
        margin: 5px;
    }
}

.modalHeader {
    font-size: 15px;
}

.listItem {
    //width: 250px; 
    //height: 30px; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.p-autocomplete {
    width: 100%;
    min-height: 2.9em;
}

.p-autocomplete .p-autocomplete-input {
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
    width: 100%;
    font-size: 1.1em !important;
    padding: .28571429em 2.1em .28571429em 1em;

}

.p-autocomplete-dd .p-autocomplete-dropdown {
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
    background-color: $cgreen;
    border-color: $cgreen;
    min-height: 3.2em;
}

.p-dropdown .p-inputtext {
    border-radius: 60px;
}

/*.p-button:enabled {
    background-color: $cgreen;
    color: #ffffff;
    border-color: $cgreen;
    border-top-right-radius: 5rem;
    border-bottom-left-radius: 5rem;
    border-top-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
}*/

.ui.list>.item a.header {
    color: $cgreen!important;
}

.containerButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
    margin-left: 20px;
    margin-right: 20px;
}

.color {
    color: $cgreen;
    font-size: 18px;
}

.acolorlink {
    color: white;
} 

.incrementButton {
    border-top-right-radius: 60px!important;
    border-bottom-right-radius: 60px!important;
    color: white;
    background-color: $cgreen!important;
}

.decrementButton {
    border-top-left-radius: 60px!important;
    border-bottom-left-radius: 60px!important;
    color: white;
    background-color: $cgreen!important;
}


.inputNumber {
    width: 100%;
    
    .p-button.p-button-icon-only {
        
        width: 2em;
    }

    .p-component {
       
        width: 50%;
    }
}