@import '../../../../variables.scss';

.container {
    display: flex;
    flex-direction: column;
    margin-top:5px;
}

.containerElement {
    margin-bottom: 30px;
}

.labelContent {
    display: flex; 
    //display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 15px;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px rgb(172, 171, 169);
    padding: 5px;
}

.containerRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.containerCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}