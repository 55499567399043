@import '../../../variables.scss';

body .p-sidebar {
    color: white;
    background-color: $green;
   
}

body .p-sidebar .p-sidebar-close {
    color: white;
}

.menuSideBar {
    background-color: $green;
    display: flex;
    flex-direction: column;
    margin-top: 10em;
}

.itemMenu {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    //margin-bottom: 20px;
}

.label {
    margin-left: 10px;
}

.line {
    border-top: 1px solid #d4d4d5;
    margin-bottom: 10px;
    margin-top: 15px;
    justify-content: center;
}

.iconColor {
   // color: $cgreen;
   color: white;
}

.logout {
    display: flex;
    flex-direction: row;
    font-size: 16px;
    align-items: flex-end;
}

.logo{
    height: 30px;
    background-color: $green;
}