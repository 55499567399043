@import '../../../../variables.scss';

.qrCardContainer {

    height: 100%;

    .qrCardTitle {
        text-align: center;
        margin-top: 8%;
        margin-bottom: 1%;
        font-weight: bold;
        font-size: 1.5em;
    }

    .qrCardParagraph {
        text-align: center;
        color: $grey;
    }

    hr {
        display: block;
        height: 1px;
        border: 0;
        width: 100%;
        border-top: 1px solid #ccc;
        margin: 0em 0;
    }

    .reservationButton {
        display: flex;
        width: 100%;
        flex-flow: row;
        justify-content: space-between;
        color: $dark-green;
        font-size: 1.4em;
        margin-top: 6%;
    }

    .reservationSection {
        width: 100%;
    }

    .reservationTitle {
        font-weight: bold;
    }

}