@import '../../../variables.scss';


.ImportModal {
    h3 {
        color: $black;
    }

    .dropzone {
        color: $detail-color;
        border: 3px dotted $detail-bg;
        padding: 2em;
        justify-content: center;
    }

    .image-icon {
        width: 3em;
        height: auto;
        margin-right: 2em;

    }

    .icon-cv19-upload {
        transform: rotate(180deg);
        display: block;
    }

    .download-link {
        text-decoration: none !important;
        display: flex;
        justify-content: left;
        align-items: center;
        color: $cgreen;

        &:hover {
            color: $light-green;
        }
    }

    .green {
        color: #489b45;
    }

    .yellow {
        color: #dfee5a;
    }

    .blue {
        color: #416ec2;
    }

    .red {
        color: #bb3b3b;
    }
}