@import './assets/style.css';
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html { font-size: 80%; }
body, html, #root, .App {
  height: 100%;

}


/* APP FONT SIZE*/

body .p-component, body .p-inputtext, .ui.menu {
  font-size: .9em !important;
}

.ui.button {
  font-size: .9em;
}

.p-paginator-icon .pi {
  line-height: 1.25em;
}

body .p-link {
  font-size: 1em !important;
}

.detail-tab .detail-title h2 {
  font-size: 1.1rem;
}

h3 {
  font-size: 1.5rem;
}

.ui.input>input {
  font-size: .9em;
  line-height: .9em;
}

.p-calendar .p-inputtext {
  min-height: 2.8em;
}

.ui.basic.button {
  padding-top: .9em;
  padding-bottom: .9em;
}

body .p-dropdown.p-component {
  min-height: 2.8em;
}

body table .p-dropdown.p-component {
  min-height: 1.5em;
}

body table .p-dropdown.p-component .p-dropdown-label.p-inputtext {
  padding-top: .26em;
  padding-bottom: .26em;
  line-height: 2.1em;
}

.p-dropdown-label {
  line-height: 2em;
}

/*-------------------*/

body, h1, h2, h3, h4, h5, h6, p, a, div, span, .ui.input>input {
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

._flex {
  display: flex !important;
}

.flex-1 {
  flex: 1 1 auto;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-row {
  flex-direction: row;
}

.w-50 {
  width: 50% !important;
}

.w-65 {
  width: 65% !important;
}

.w-35 {
  width: 35% !important;
}

.w-70 {
  width: 70% !important;
}

.w-30 {
  width: 30% !important;
}

.w-80 {
  width: 80% !important;
}

.h-90 {
  height: 90% !important;
}

.w-45 {
  width: 45% !important;
}

.w-10 {
  width: 10% !important;
}

.absolute {
  position: absolute;
}

.circle {
  display: flex;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 5px;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;
}

.circle:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}

.circle span {
  display: inline-block;
  vertical-align: middle;
}

/* SEMANTIC UI*/

.ui.basic.modal {
  background: #fff;
  border-radius: 20px;
}

.ui.header .content, .ui.header .icon, .ui.basic.modal>.header {
  color: rgba(127, 127, 127, 1);
}

.ui.header i {
  margin-right: 10px;
}

.ui.button:not(.icon)>.icon:not(.button):not(.dropdown) {
  margin: 0;
}

.ui.segment[class*="bottom attached"] {
  margin-bottom: 0;
}

/*PRIMEREACT*/

body .p-datatable .p-datatable-tbody>tr {
  border-bottom: 1px solid #f0f0f0;
}

body .p-datatable .p-datatable-tbody>tr>td, body .p-datatable .p-datatable-thead>tr>th {
  border: none !important;
  padding-top: .7em !important;
  padding-bottom: .7em !important;
}

body .p-datatable .p-datatable-tbody>tr.p-highlight, body .p-datatable .p-datatable-tbody>tr:nth-child(even).p-highlight {
  background-color: rgba(48, 185, 151, 1) !important;
}

body .p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: rgba(96, 194, 107, 1) !important;
}

body .p-checkbox .p-checkbox-box.p-highlight {
  background-color: rgba(255, 255, 255, 1) !important;
  border-color: rgba(96, 194, 107, 1) !important;
}

.p-datatable .p-paginator-bottom {
  border: none;
}

body .p-datatable .p-datatable-tbody>tr:nth-child(even) {
  background-color: transparent !important;
}

body .p-datatable .p-datatable-thead>tr>th {
  text-align: left !important;
}

.p-datatable .p-datatable-tbody>tr {
  text-align: left;
}

.p-dropdown.query, .p-dropdown.query .p-dropdown-label {
  border-radius: 5rem !important;
}

.p-dropdown-trigger {
  background-color: transparent !important;
}

.p-paginator {
  background-color: transparent !important;
  margin-top: 2rem !important;
  display: flex;
  justify-content: flex-end;
}

.p-paginator .p-paginator-element {
  border: 1px solid #cecece !important;
  margin-right: .4rem !important;
  margin-left: .4rem !important;
}

body .p-datatable .p-sortable-column.p-highlight, .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: rgba(48, 185, 151, 1) !important;
  border: none !important;
}

body .p-datepicker table td>span.p-highlight {
  background-color: rgba(48, 185, 151, 1) !important;
}

/*THIRD PARTY INPUTS COMMON*/

.ui.button, body .p-inputtext {
  font-family: 'Poppins' !important;
}

.p-inputtext, .p-dropdown {
  border-color: rgba(34, 36, 38, .15) !important;
}


.bgRoundGradient {
  overflow: hidden;
  position: fixed;
  width: 200%;
  background: transparent linear-gradient(180deg, rgba(45, 187, 84, 1) 0%, rgba(39, 187, 204, 1) 100%) 0% 0% no-repeat padding-box;
  border-radius: 50%;
  left: -50%;
}

@media(max-width:767px) {
  html { font-size: 70%; }
}



@media screen and (min-width: 1024px) {
  .cardContainer {
    position: relative;
    max-height: 80%;
    height: 80%;
  }
  
  .cardBody {
    padding: 0; /*!important;*/
  }

  .cardBody > div {
    max-height: 70%;
    position: relative;
    height: 70%;
  }

  .cardBody div img {
    width: 100%;
    max-height: 80%;
    position: relative;
    height: 80%;
  }

  .qrCardTitle {
    margin-top: 4%;
  }
  
  .homeContainer {
    max-width: 1024px;
    margin: 0 auto;
  }

  .cardBody > div > section {
    width: 50% !important;
    margin: 0 auto;
  }

}