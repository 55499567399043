.Logs {
    .p-dropdown label.p-dropdown-label {
        text-align: left;

    }

    .p-dropdown-filter-container {
        width: 100%;
    }

    .p-calendar .p-inputtext {
        border-radius: 5rem !important;
        min-width: 15em;
    }

    .wrapped {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}