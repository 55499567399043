@import '../../../../../variables.scss';
@import '../../../../../mediaqueries.scss';

.Location {
    width: 75% !important;
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$toolbox-buttons-height});

    &.full {
        width: 100% !important;
    }


    .NoLocation {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $detail-color;
        flex-direction: column;

        i {
            font-size: 2em;
            cursor: pointer;

            &:hover {
                color: $green;
            }
        }
    }

    .toolbox {
        // position: absolute;
        height: 40px;
        margin-right: 30px;
    }

    .ui.dropdown .menu>.divider {
        margin-bottom: 0
    }

    .ui.dropdown .menu>.item.delete {
        color: $delete-red;
    }

    .ui.dropdown {
        .menu {
            max-height: max-content;
        }

        .text {
            font-size: .9rem;
        }
    }

    .rst__tree {
        height: calc(100% - #{$tbh-offset}) !important;
        max-width: 80%;
        position: relative;
        margin-left: 20%;

        &.wsman {

            .rst__rowTitleWithSubtitle {
                margin-bottom: .5em;
            }

            .rst__rowSubtitle {
                display: none;
            }
        }
    }

    // .rst__tree>div:first-child {
    //     width: auto !important;
    //     /* display: block; */
    //     // position: relative;
    //     // transform: translateX(-25%);
    //     // left: 50%;
    //     // max-width: 60%;

    //     div.ReactVirtualized__Grid {
    //         max-width: 100%;
    //     }
    // }


    .rst__toolbarButton input[aria-autocomplete='list'] {
        display: none;
    }

    .rst__moveHandle {
        background: $cgreen url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+') no-repeat center;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border: none;
        -webkit-box-shadow: 0 2px 2px -2px;
        box-shadow: 0 2px 2px -2px;
    }

    .rst__expandButton {
        background: $cgreen url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxpdmVsbG9fMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDE4IDE4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOCAxODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtzdHJva2U6I0ZGRkZGRjtzdHJva2Utd2lkdGg6MS45O30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNC41LDloOSIvPgoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTksNC41djkiLz4KPC9nPgo8L3N2Zz4K') no-repeat center;
        -webkit-box-shadow: 0 0 0 1px $green;
        box-shadow: 0 0 0 1px $green;
    }

    .rst__collapseButton {
        background: $cgreen url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxpdmVsbG9fMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDE4IDE4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxOCAxODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtzdHJva2U6I0ZGRkZGRjtzdHJva2Utd2lkdGg6MS45O30KPC9zdHlsZT4KPGc+Cgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNNC41LDloOSIvPgo8L2c+Cjwvc3ZnPgo=') no-repeat center;
        -webkit-box-shadow: 0 0 0 1px $green;
        box-shadow: 0 0 0 1px $green;
    }

    .rst__collapseButton:focus,
    .rst__expandButton:focus {
        -webkit-box-shadow: 0 0 0 1px $green;
        box-shadow: 0 0 0 1px $green;
    }

    .rst__rowContents {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .rst__row {
        cursor: pointer;
        min-width: 450px;
    }


    .seats-available {
        font-size: .8em;
    }

    .rst__row.active {
        .rst__rowContents {
            background: $cgreen;
        }


        .rst__rowTitle, .rst__rowSubtitle, .seats-available {
            color: $white;
        }


        .rst__toolbarButton {
            // background: $white;

            .ui.dropdown {
                background: white;
                border-radius: 4em;
                text-align: center;
                padding: .2em;
                padding-bottom: .3em;
                padding-left: .5em;
                padding-right: .5em;
            }

            i {
                color: $black;
            }
        }

    }

    .rst__row.node_disabled {
        .rst__rowContents {
            background: $detail-color !important;
        }

        .rst__rowTitle {
            color: $white;
        }

        .rst__rowSubtitle {
            color: $white;
        }

        .rst__toolbarButton {
            // background: transparent;

            .ui.dropdown {
                background: white;
                border-radius: 4em;
                text-align: center;
                padding: .2em;
                padding-bottom: .3em;
                padding-left: .5em;
                padding-right: .5em;
            }
        }

    }

    .rst__toolbarButton {
        border-radius: 10px;
        // border: 1px solid $detail-bg;
        padding-right: .6rem;
        padding-left: .6rem;
        cursor: pointer;
        background: transparent;

        i.icon-cv19-add {
            color: $detail-color;
        }
    }

    .rst__lineChildren::after,
    .rst__lineHalfHorizontalRight::before,
    .rst__lineFullVertical::after,
    .rst__lineHalfVerticalTop::after,
    .rst__lineHalfVerticalBottom::after {
        background-color: $block-line-color;
    }

    .rst__lineChildren::after,
    .rst__lineFullVertical::after,
    .rst__lineHalfVerticalTop::after,
    .rst__lineHalfVerticalBottom::after {
        width: 2px;
    }

}

@media screen and (min-width:992px) and (max-width: 1300px) {

    .Location {
        width: 65% !important;
    }
}