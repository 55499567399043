@import '../../../variables.scss';

.cardContainer {
    position: relative;
    max-height: 70%;
    height: 70%;

    .cardBody {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5% 10% 1% 10%;
        background-color: white;
        border-radius: 20px;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.40);
        height: 100%;
        overflow-y: auto;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .cardBody::-webkit-scrollbar {
        display: none;
    }
}





