@import '../../../../../variables.scss';


.Settings {
    @media (min-width: 768px){
        .col-md-9 {
            flex: 0 0 75%;
            max-width: 70%;
        } 
    }
    
    .label {
        font-weight: 700;
        color: $black;
        align-items: center;
        display: flex;
    }

    .close.icon {
        color: $detail-color;
        ;
    }

    .panel-custom {
        width: 80%;
        margin-left: 10%;

        .p-panel-titlebar{
            border: 1px solid #c8c8c8;
            padding: 0.571em 1em;
            background-color: $green; //#fff;
            color: #fff;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            .p-panel-titlebar-icon {
                margin: 0;
                color: #fff;
                transition-duration: 0.8s;
                transition-property: transform;
            }
            .p-panel-titlebar-icon:hover {
                color: rgb(238, 238, 238);
                transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
            }
            .p-panel-titlebar-icon:focus {
                outline: 0 none;
                outline-offset: 0;
                box-shadow: none; 
            }
        }
    }
}

.m-custom {
    margin-top: 30px;
    margin-bottom: 30px;
}

.m-b-custom {
    margin-bottom: 30px;
}

.m-t-custom {
    margin-top: 30px;
}


.m-panel {
    margin-bottom: 10px;
}


.detail-form {
    width: 100%;
    // border-top: 1px solid #d4d4d5;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .p-autocomplete {
        width: 100%;
        min-height: 2.9em;
    }

    .p-autocomplete .p-autocomplete-input {
        border-top-left-radius: 5rem;
        border-bottom-left-radius: 5rem;
        width: 100%;
        font-size: 1.1em !important;
        padding: .28571429em 2.1em .28571429em 1em;

    }

    .p-autocomplete-dd .p-autocomplete-dropdown {
        border-top-right-radius: 5rem;
        border-bottom-right-radius: 5rem;
        background-color: $cgreen;
        border-color: $cgreen;
        min-height: 3.2em;
    }

    .card {
        padding-top: 1em;
        padding-bottom: 1em;
        padding-right: 1em;
        padding-left: 1em;
        margin-top: 1em;
        margin-bottom: 1em;
        border-radius: .8rem;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);



        &.desk {
            padding: 0;


            .desk-title {
                border-top-left-radius: .8rem;
                border-top-right-radius: .8rem;
                background: $cgreen;
                color: $white;

                display: flex;
                justify-content: flex-start;
                align-items: center;
                cursor: pointer;

                .container {
                    padding: .8em;

                    i {
                        margin-right: .6em;
                        font-size: 1.5em;
                    }

                    h5 {
                        font-size: 1em;
                        font-weight: 700;
                    }
                }

                i.icon-cv19-close {
                    display: flex;
                    position: relative;
                    z-index: 2;
                    font-size: 1em;
                    cursor: pointer;
                    margin-right: 1.3em;

                    &:hover {
                        opacity: .5;
                    }
                }

                &.disabled {
                    background: $detail-color;
                }


            }

            .desk-body {
                background: $white;
                color: $detail-color;
                align-items: center;
                justify-content: center;
                margin-top: 1em;
                padding-right: 1em;
                padding-left: 1em;

                .seats-label {
                    font-size: .9em;
                }

            }

            hr {
                margin-bottom: .5em;
            }

            .desk-footer {
                margin-bottom: .3em;
            }

            &.closed {

                .desk-body,
                .desk-footer,
                hr {
                    display: none;
                }

                .desk-title {
                    border-radius: .8rem;

                    i.icon-cv19-close {
                        display: none;
                    }
                }
            }

        }


        .label {
            color: $detail-color;
            text-align: left;
            font-size: 1.1em;

            i {
                font-size: 1.3em;
                margin-right: .5em;
            }
        }

        .ui.basic.button.basic-icon-button {
            border: none;
            box-shadow: none;
            font-size: .8em;
            display: flex;
            align-items: center;

            i {
                font-size: 1.3em;
                margin-right: .2em;
            }
        }

    }
}

.Contacts {

    .p-autocomplete .p-autocomplete-input {
        border-top-left-radius: 5rem;
        border-bottom-left-radius: 5rem;
    }

    .p-autocomplete-dd .p-autocomplete-dropdown {
        border-top-right-radius: 5rem;
        border-bottom-right-radius: 5rem;
        background-color: $cgreen;
        border-color: $cgreen;
    }

    .p-calendar input {
        border-radius: 5rem !important;
        min-width: 15em;
    }

    .p-calendar input.filterCalendar {
        border-radius: 0px !important;
        min-height: 2.5em;
    }


    .p-dropdown label.p-dropdown-label {
        text-align: left;

    }

    .p-dropdown-filter-container {
        width: 100%;
    }


}

.error {
    color: rgb(182, 26, 26)
}



.ui.selection.dropdown {
    cursor: pointer;
    word-wrap: break-word;
    line-height: 1em;
    white-space: normal;
    outline: 0;
    -webkit-transform: rotateZ(0);
    transform: rotateZ(0);
    min-width: 14em;
    min-height: 2.71428571em;
    background: #fff;
    display: inline-block;
    padding: .78571429em 2.1em .78571429em 1em;
    color: rgba(0,0,0,.87);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    -webkit-transition: width .1s ease,-webkit-box-shadow .1s ease;
    transition: width .1s ease,-webkit-box-shadow .1s ease;
    transition: box-shadow .1s ease,width .1s ease;
    transition: box-shadow .1s ease,width .1s ease,-webkit-box-shadow .1s ease;
}
.ui.dropdown {
    cursor: pointer;
    position: relative;
    display: inline-block;
    outline: 0;
    text-align: left;
    -webkit-transition: width .1s ease,-webkit-box-shadow .1s ease;
    transition: width .1s ease,-webkit-box-shadow .1s ease;
    transition: box-shadow .1s ease,width .1s ease;
    transition: box-shadow .1s ease,width .1s ease,-webkit-box-shadow .1s ease;
    -webkit-tap-highlight-color: transparent;
}

.label-checkbox {
    // display: inline-block;
    // vertical-align:middle;
    margin-bottom: 10px;
}
