@media screen and (min-width:992px) and (max-width: 1200px) {
    
    .detail-tab {
        width: 35% !important;
        right: -35%;
    }

    .Location {
        width: 65% !important;
    }
}