@import '../../../variables.scss';

.MotivationsModal {
    .p-datatable .p-datatable-thead > tr > th {
        text-align: left !important;
    }

    .close.icon {
        color: $detail-color;;
    }
}