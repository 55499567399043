@import "../../../../../variables.scss";

.detail-tab {
    background: $detail-bg;
    position: fixed;
    right: -25%;
    top: 60px;
    width: 25%;
    z-index: 2;
    padding-right: 2rem;
    padding-left: 2rem;

    &.active {
        right: 0;

    }

    .detail-title {
        height: $tab-height;
        align-items: center;
        justify-content: space-between;
        display: flex;
        flex-shrink: 0;

        h2 {
            margin: 0;
            color: $detail-color;

            text-transform: uppercase;
            text-align: left;
        }

        .detail-title-buttons {
            .print {
                background: $white;
                font-size: .9rem;
                color: $black;

                &:hover {
                    background: $detail-bg;
                }
            }

            .close {
                color: $detail-color;
                font-size: 1.3rem;
                cursor: pointer;
            }
        }
    }

    .detail-form {
        width: 100%;
        border-top: 1px solid #d4d4d5;
        padding-top: 2rem;
        padding-bottom: 2rem;

        .p-autocomplete {
            width: 100%;
            min-height: 2.9em;
        }
    
        .p-autocomplete .p-autocomplete-input {
            border-top-left-radius: 5rem;
            border-bottom-left-radius: 5rem;
            width: 100%;
            font-size: 1.1em !important;
            padding: .28571429em 2.1em .28571429em 1em;
    
        }
    
        .p-autocomplete-dd .p-autocomplete-dropdown {
            border-top-right-radius: 5rem;
            border-bottom-right-radius: 5rem;
            background-color: $cgreen;
            border-color: $cgreen;
            min-height: 3.2em;
        }

        .card {
            padding-top: 1em;
            padding-bottom: 1em;
            padding-right: 1em;
            padding-left: 1em;
            margin-top: 1em;
            margin-bottom: 0em;
            border-radius: .8rem;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);



            &.desk {
                padding: 0;


                .desk-title {
                    border-top-left-radius: .8rem;
                    border-top-right-radius: .8rem;
                    background: $cgreen;
                    color: $white;

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;

                    .container {
                        padding: .8em;

                        i {
                            margin-right: .6em;
                            font-size: 1.5em;
                        }

                        h5 {
                            font-size: 1em;
                            font-weight: 700;
                        }
                    }

                    i.icon-cv19-close {
                        display: flex;
                        position: relative;
                        z-index: 2;
                        font-size: 1em;
                        cursor: pointer;
                        margin-right: 1.3em;

                        &:hover {
                            opacity: .5;
                        }
                    }

                    &.disabled {
                        background: $detail-color;
                    }


                }

                .desk-body {
                    background: $white;
                    color: $detail-color;
                    align-items: center;
                    justify-content: center;
                    margin-top: 1em;
                    padding-right: 1em;
                    padding-left: 1em;

                    .seats-label {
                        font-size: .9em;
                    }

                    .border-none {
                        border: none !important;
                    }

                    .ui.input {
                        border: none !important;
                    }

                }

                hr {
                    margin-bottom: .5em;
                }

                .desk-footer {
                    margin-bottom: .3em;
                }

                &.closed {

                    .desk-body,
                    .desk-footer,
                    hr {
                        display: none;
                    }

                    .desk-title {
                        border-radius: .8rem;

                        i.icon-cv19-close {
                            display: none;
                        }
                    }
                }

            }


            .label {
                color: $detail-color;
                text-align: left;
                font-size: 1.1em;

                i {
                    font-size: 1.3em;
                    margin-right: .5em;
                }
            }

            .ui.basic.button.basic-icon-button {
                border: none;
                box-shadow: none;
                font-size: .8em;
                display: flex;
                align-items: center;

                i {
                    font-size: 1.3em;
                    margin-right: .2em;
                }
            }

        }
    }

}


@media screen and (max-width: 1300px) {
    .detail-tab {
        .detail-form {
            .card {
                padding-right: .7em;
                padding-left: .7em;
            }
        }
    }
}

@media screen and (min-width:992px) and (max-width: 1300px) {

    .detail-tab {
        width: 35% !important;
        right: -35%;
    }

}

.icon-close {
    cursor: pointer;
    height: 2em;
    display: flex;
}

.labelstyle{
    .ui.image.label  {
        //width: 100%;
        justify-content: center!important;
        display: flex!important;
        max-width: 100%;
        background: white!important;
        border-radius: 60px!important;
        align-items: center;
        height: 3em;
    }
    
    .ui.image.label .detail {
        background: white!important;
        border-radius: 60px!important;
    }
}
   
