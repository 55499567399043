@import '../../variables.scss';

.Forgot {
  background: transparent linear-gradient(180deg, $green 0%, $cyan 100%) 0% 0% no-repeat padding-box;

  width: 100%;
  margin: auto;
  height: 250px;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;

  .ui.buttons.app-switcher {

    border: none;

    .button {
      background-color: transparent;
      border: 2px solid $white;
      min-height: 3.5em;
      color: $white !important;
      font-weight: 400;

      &.active,
      &:hover {
        background: $white !important;
        background-color: $white !important;
        color: $black !important;
      }

    }

    .button:first-child {
      border-top-left-radius: 5rem !important;
      border-bottom-left-radius: 5rem !important;
    }

    .button:last-child {
      border-top-right-radius: 5rem !important;
      border-bottom-right-radius: 5rem !important;
    }
  }

  .select-app {
    color: $white;
  }


  
    
    @keyframes ui-progress-spinner-color {
      100%,
      0% {
          stroke: #767676;
      }
      40% {
          stroke: #767676;
      }
      66% {
          stroke: #767676;
      }
      80%,
      90% {
          stroke: #767676;
      }
  }

  .loader {
    content: '';
    margin: 1em 0 0 1em;
    width: 5em;
    height: 5em;
    animation: button-spin .6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #fff transparent transparent;
    border-style: solid;
    border-width: .5em;
    box-shadow: 0 0 0 1px transparent;
    z-index: 999;
}

.container-opaque {
  width: 100vw;
  height: 100vh;
  padding-top: 50vh;
  background-color: #000000;
  opacity: 0.2;
  z-index: 998;
}

.error-label {
  color: $delete-red;
  font-size: 1rem;
  text-align: left;
}

.info-text {
  color: $white;
  font-size: 1rem;
  text-align: left;
}

.info-container {
  background-color:  rgba(68, 173, 140, 0.5);
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px 20px 10px 20px;
}


}




.forgot-img {
  background: url("../../assets/imgs/HP_INSPIRING-TRUST.png") no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: .45;
  z-index: 1;
}

.forgot-form {
  z-index: 2;
  position: relative;
}

form {
  margin-top: 2.5em;
}

.forgot-title {
  font-size: 10rem;
  color: $white;
  font-weight: 700;
  margin: 0 auto;
}

.forgot-subtitle {
  font-size: 3rem;
  color: $white;
  font-weight: 300;
  margin: 0 auto;
}


@media(min-width:768px) {}

@media(min-width:992px) {}

@media(min-width:1200px) {}