@import '../../../../variables.scss';


.SelfCertification{
    .container {
        display: flex;
        flex-direction: column;
        margin-top:5px;
    }
    
    .qrCardContainer {
    
        height: 100%;
    .qrCardParagraph {
        text-align: center;
        color: $grey;
    }
    }
    
    .containerRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .containerCol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .containerElement {
        margin-bottom: 30px;
    }
        
    .labelContent {
        display: flex; 
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 15px;
        border-radius: 20px;
        background-color: white;
        box-shadow: 0px 0px 10px 0px rgb(172, 171, 169);
        padding: 5px;
    }
        
    .labelEl {
        width: 250px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center; 
        font-size: 15px;
        border-radius: 20px;
        color: $cgreen;
    }
    
    .separator {
        height: 30px;
        border-left: 1px solid grey;
        margin-right: 10px;
    }
    
    .separatorH {
        border:none;
        border-top: 1px solid #d4d4d5;
        height: 100%;
    }
    
    .marginIconLeft {
        display: flex;
        color: $cgreen;
        //margin-left: 2em;
        justify-content: flex-end;
    }
    
    .marginIconRight {
        color: $cgreen;
        margin-right: 20px;
    }
    
    .calendar {
        display: contents;
        margin-top: 20px;
        .p-datepicker table {
            font-size: 10px;
            margin: 0.857em 0 0 0;
        }
        .p-datepicker.p-datepicker-inline {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
    
    .modalHeader {
        font-size: 15px;
    }
    
    .listItem {
        //width: 250px; 
        //height: 30px; 
        display: flex; 
        justify-content: center; 
        align-items: center;
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
