@import '../../../variables.scss';

    .homeContainer {
        padding: 0 8% 6%;
        height: 100%;

    .actionButton {
        margin: 0;
        margin-top: 5%;
        width: 100%;
        box-shadow: $cyan 0 0 10px;
        background: transparent linear-gradient(90deg, $green 0%, $cyan 100%) 0% 0% no-repeat padding-box;
        color: white;
        border-radius: 5rem;
        padding-top: 1.3em;
        padding-bottom: 1.3em;
    }
    .cancelButtonGrey {
        margin: 0;
        margin-top: 5%;
        width: 100%;
        box-shadow: $gray 0 0 10px;
        background: transparent linear-gradient(90deg, $detail-color 0%, $gray 100%) 0% 0% no-repeat padding-box;
        color: white;
        border-radius: 5rem;
        padding-top: 1.3em;
        padding-bottom: 1.3em;
    }
    .roundIcon {
        padding: 1vh;
        border-radius: 50%;
        background-color: $cyan;
        color: white;
    }

    .fullScreenSpinner {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: black;
        opacity: 0.8;
    }

    .buttonText {
        display: block;
        padding-top: 22px;
        text-align: center;
        line-height: 1.1em;    
    }

    .buttonSection {
        margin-top: 5%;
    }

        
    .tagButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        margin-top: 5%;
        width: 100%;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.20);
        background: white;
        border-radius: 5rem;
    }

    .ui.attached.tabular.menu{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

